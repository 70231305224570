.item {
}
.item__li {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.item__li + .item__li {
  margin-top: 48px;
}
.item__li__pic {
  width: 600px;
  height: 400px;
  flex-shrink: 0;
  display: block;
  position: relative;
}
.item__li__pic:hover {
  opacity: 0.8;
}
.item__li:nth-child(odd) .item__li__pic {
  order: 0;
  margin-right: 32px;
}
.item__li:nth-child(odd) .item__li__info {
  order: 1;
}
.item__li:nth-child(even) .item__li__pic {
  order: 1;
  margin-left: 32px;
}
.item__li:nth-child(even) .item__li__info {
  order: 0;
}
.item__li__pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.item__li__info {
}
.item__li__info__ttl {
  font-size: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-break: break-all;
}
.item__li__info__tag {
  margin-top: 16px;
  margin-bottom: -4px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.item__li__info__tag p {
  font-size: 12px;
  line-height: 22px;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  padding: 0 4px;
  margin-right: 4px;
  margin-bottom: 4px;
  display: inline-block;
  word-break: break-all;
}
.item__li__info__desc {
  margin-top: 16px;
  font-size: 14px;
  color: #888;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
}
.item__li__info__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
}
.item__li__info__bottom__left {
}
.item__li__info__bottom__left__week {
  font-size: 14px;
}
.item__li__info__bottom__left__erea {
  font-size: 16px;
  font-weight: bold;
}
.item__li__info__bottom__price {
  font-size: 24px;
  font-weight: bold;
  text-align: right;
}
.item__li__info__btn {
  width: 240px;
  height: 40px;
  font-family: 'Raleway', 'ヒラギノ角ゴ Pro', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo',
    sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.1em;
  border-radius: 2px;
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.item__li__info__btn::after {
  content: '';
  width: 8px;
  height: 8px;
  border-top-width: 1px;
  border-right-width: 1px;
  border-top-style: solid;
  border-right-style: solid;
  transform: rotate(45deg);
  position: absolute;
  top: calc(50% - 4px);
  right: 16px;
}
.item__li__info__btn:hover {
  opacity: 0.8;
}
@media screen and (max-width: 768px) {
  .item__li {
    display: block;
  }
  .item__li__pic {
    width: 100%;
    height: 240px;
    flex-shrink: 0;
  }
  .item__li__pic:hover {
    opacity: 0.8;
  }
  .item__li:nth-child(odd) .item__li__pic {
    margin-right: 0;
  }
  .item__li:nth-child(even) .item__li__pic {
    margin-left: 0;
  }
  .item__li__info {
    display: flex;
    flex-direction: column;
  }
  .item__li__info > * {
    order: 2;
  }
  .item__li__info__ttl {
    font-size: 18px;
    -webkit-line-clamp: 2;
    margin-top: 8px;
    order: 0;
  }
  .item__li__info__tag {
    margin-top: 8px;
    order: 1;
  }
  .item__li__info__desc {
    margin-top: 8px;
  }
  .item__li__info__bottom {
    margin-top: 16px;
  }
  .item__li__info__btn {
    margin-left: auto;
    margin-right: auto;
  }
  .item__li__info__bottom__left__week {
    font-size: 12px;
  }
  .item__li__info__bottom__left__erea {
    font-size: 14px;
  }
  .item__li__info__bottom__price {
    font-size: 18px;
  }
}
@media screen and (max-width: 768px) {
}
